<template>
  <b-card class="w-100 facilities">
    <b-card-title>Tags</b-card-title>
    <b-card-sub-title>
      This section is for the facilities that are available in your property. These facilities will be seen by guests when they search for a place to stay.
    </b-card-sub-title>
    <b-row
      align-h="between"
      class="facility-names mb-2"
    >
      <b-col
        v-for="tag in tags"
        :key="tag.id"
        md="4"
        class="room-info"
      >
        <div class="services">
          <b-form-checkbox
            :id="`${tag.id}`"
            :key="tag.id"
            v-model="selectedTags"
            :value="tag.id"
            class="my-1"
          >
            {{ tag.text }}
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <div class="my-1 text-right">
      <b-button
        variant="primary"
        :disabled="isBtnLoading"
        @click="updateTagList"
      >
        <b-spinner
          v-if="isBtnLoading"
          small
        />
        Submit
      </b-button>
    </div>
    <div
      class="text-right py-2 border-top"
    >
      <b-button
        variant="outline-primary"
        :to="`/tour-register/step-4/${tourId}`"
      >
        Photos
        <feather-icon
          icon="ArrowRightIcon"
          size="12"
        />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import tour from '@/mixins/tour'

export default {
  name: 'Tags',
  mixins: [helper, tour],
  data() {
    return {
      isBtnLoading: false,
      searchQuery: '',
      tags: '',
      selectedTags: [],
      tourId: this.$store.state.tour.property.id,
    }
  },
  mounted() {
    this.getTagList()
    this.isTourValid(this.$route.params.id, this.getTourTags)
  },
  methods: {
    getTourTags() {
      api.getData(`tour/admin_services/tour/${this.$route.params.id}/tags/`, true)
        .then(response => {
          if (response.data.status) {
            this.selectedTags = response.data.result.map((tag => tag.id))
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, your request couldn\'t be processed')
        })
    },
    getTagList() {
      api.getData('tour/admin_services/tour_tag_list/', true)
        .then(response => {
          if (response.data.status) {
            this.tags = response.data.result
          }
        }).catch().finally(() => {
        })
    },
    updateTagList() {
      this.isBtnLoading = true
      api.updateData(`tour/admin_services/tour/${this.tourId}/tour_update/`, true, { tags: this.selectedTags }).then(response => {
        if (response.data.status) {
          this.notificationMessage('success', 'CheckIcon', 'Success', 'policy updated successfully')
          this.$router.push(`/tour-register/step-4/${response.data.result.id}`)
        } else {
          this.notificationMessage('danger', 'Xicon', 'Error', JSON.stringify(response.data.error.message))
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isBtnLoading = false
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@/assets/scss/tour.scss';
</style>
